import request from '@/utils/request'


// 查询活动弹框列表
export function listPush(query) {
  return request({
    url: '/activity/activity-pushs/list',
    method: 'get',
    params: query
  })
}

// 查询活动弹框分页
export function pagePush(query) {
  return request({
    url: '/activity/activity-pushs/page',
    method: 'get',
    params: query
  })
}

// 查询活动弹框详细
export function getPush(data) {
  return request({
    url: '/activity/activity-pushs/detail',
    method: 'get',
    params: data
  })
}

// 新增活动弹框
export function addPush(data) {
  return request({
    url: '/activity/activity-pushs/add',
    method: 'post',
    data: data
  })
}

// 修改活动弹框
export function updatePush(data) {
  return request({
    url: '/activity/activity-pushs/edit',
    method: 'post',
    data: data
  })
}

// 删除活动弹框
export function delPush(data) {
  return request({
    url: '/activity/activity-pushs/delete',
    method: 'post',
    data: data
  })
}
