<template>
  <a-drawer width="80%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <!--      <a-form-model-item label="短剧id 外键{zb_video.id}" prop="videoId" >-->
      <!--        <a-input v-model="form.videoId" placeholder="请输入短剧id 外键{zb_video.id}" />-->
      <!--      </a-form-model-item>-->
      <a-form-model-item label="活动封面" prop="videoCover">
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          v-model="form.videoCover"
          :showUploadList="false"
          :customRequest="handleChange"
        >
          <img v-if="form.videoCover" :src="form.videoCover" alt="logo" style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>

      <a-form-model-item label='跳转链接' prop='versionName'>
        <a-input v-model='form.h5Url' placeholder='请输入跳转链接'/>
      </a-form-model-item>

      <!--      <a-form-model-item label="是否推迟明天发送" prop="isTomorrow" >-->
      <!--        <a-select placeholder="请选择是否推迟明天发送" v-model="form.isTomorrow" >-->
      <!--          <a-select-option value="0">否</a-select-option>-->
      <!--          <a-select-option value="1">是</a-select-option>-->
      <!--        </a-select>-->
      <!--      </a-form-model-item>-->
      <a-form-model-item label="发送时间" prop="sendTime">
        <a-date-picker style="width: 100%" v-model="form.sendTime" format="YYYY-MM-DD HH:mm" allow-clear/>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {getPush, addPush, updatePush} from '@/api/activity/push'
import UploadFileToVOD from "@/api/vod";

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      uploadLoading: false,
      // 表单参数
      form: {
        id: null,

        videoId: null,

        videoCover: null,

        type: null,

        isTomorrow: null,
        h5Url: null,

        sendTime: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        type: [
          {required: true, message: '类型0-新剧上映通知不能为空', trigger: 'change'}
        ]
      }
    }
  },
  filters: {},
  created() {
  },
  computed: {},
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    handleChange(info) {
      let that = this
      new UploadFileToVOD([info.file], {
        success: function (fileName) {
          debugger
          console.log(fileName)
          that.form.videoCover = fileName[0];//文件名
          that.uploadLoading = false;
        }
      });
      that.uploadLoading = false;
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        videoId: null,
        videoCover: null,
        type: null,
        isTomorrow: null,
        sendTime: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getPush({"id": id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },


    formatDateTime(dateTimeStr) {
      // 假设dateTimeStr是形如"YYYY-MM-DD HH:mm:ss"的字符串
      const dateTime = new Date(dateTimeStr);

      // 检查日期是否有效
      if (isNaN(dateTime.getTime())) {
        throw new Error('Invalid date time string');
      }

      // 获取并格式化日期和时间的各个部分
      const year = dateTime.getFullYear();
      const month = String(dateTime.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以需要+1
      const day = String(dateTime.getDate()).padStart(2, '0');
      const hours = String(dateTime.getHours()).padStart(2, '0');
      const minutes = String(dateTime.getMinutes()).padStart(2, '0');
      const seconds = String(dateTime.getSeconds()).padStart(2, '0');

      // 使用模板字符串进行格式化
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },

    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          this.form.sendTime = this.formatDateTime(this.form.sendTime)
          if (this.form.id !== undefined && this.form.id !== null) {
            updatePush(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addPush(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
